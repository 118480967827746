/* eslint-disable no-underscore-dangle */

import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import setRealtimeDB from '../../api/setRealtimeDB';
import { firebaseConfig } from '../../api/firebaseConfig';
import styles from './Login.module.css';

const Login = () => {
  const router = useNavigate();
  initializeApp(firebaseConfig);

  const googleLogin = () => {
    const provider = new GoogleAuthProvider();

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const { user } = result;

        if (result._tokenResponse.isNewUser) {
          const userData = {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          };
          setRealtimeDB(user.uid, userData);
        }

        localStorage.setItem('login', JSON.stringify({ user, token }));
        router.push('/main');
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // const { email } = error.customData;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error(errorCode, errorMessage, credential);
      });
  };

  let id = null;
  useEffect(() => {
    if (typeof window !== 'undefined') {
      id = JSON.parse(localStorage.getItem('login'))?.user?.uid;
    }

    if (!id) {
      return;
    }

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        router.push('/main');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className={styles.Login}>
      <button onClick={googleLogin}>구글 로그인</button>
    </div>
  );
};

export default Login;
