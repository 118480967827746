import React, { useState } from 'react';

import { hasJongseong } from '../../utils/hasJongSeong';

const FamilySetting = ({ position, styles, formData, setFormData }) => {
  const [showMoreFamily, setShowMoreFamily] = useState(false);

  const handleChange = (e, section, index, key) => {
    const { value } = e.target;

    if (value === 'moreFamily') {
      setShowMoreFamily(true);
    }

    setFormData((prevData) => {
      const newData = { ...prevData };
      if (index !== null) {
        newData[section][index][key] = value;
      } else {
        newData[section][key] = value;
      }
      return newData;
    });
  };

  const addFamilyMember = () => {
    setFormData((prevData) => {
      const newMember = { accountNumber: '', name: '', phone: '', role: 'dad', position: '' };
      return {
        ...prevData,
        familyInfo: { ...prevData.familyInfo, [position]: [...prevData.familyInfo[position], newMember] },
      };
    });
  };

  const familyType = position === 'bride' ? '신부' : '신랑';

  const familyMembers = [
    { value: 'dad', label: '아버지' },
    { value: 'mom', label: '어머니' },
    { value: 'mom_grandfather', label: '외조부' },
    { value: 'mom_grandmother', label: '외조모' },
    { value: 'dad_grandfather', label: '친조부' },
    { value: 'dad_grandmother', label: '친조모' },
  ];

  const moreFamilyMembers = [
    { value: 'mom_sister', label: '이모' },
    { value: 'mom_sister_spouse', label: '이모부' },
    { value: 'dad_sister', label: '고모' },
    { value: 'dad_sister_spouse', label: '고모부' },
    { value: 'mom_brother', label: '외숙부' },
    { value: 'mom_brother_spouse', label: '외숙모' },
    { value: 'dad_older_brother', label: '큰아버지' },
    { value: 'dad_older_brother_spouse', label: '큰어머니' },
    { value: 'dad_younger_brother', label: '작은아버지' },
    { value: 'dad_younger_brother_spouse', label: '작은어머니' },
    { value: 'brother', label: `${position === 'bride' ? '오빠' : '형'}` },
    { value: 'sister', label: `${position === 'bride' ? '언니' : '누나'}` },
  ];

  const renderOption = ($target) => {
    return $target.map((e) => (
      <option key={e.value} value={e.value}>
        {e.label}
      </option>
    ));
  };

  return (
    <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name={`${position}Family`}>
      {/* Family Info */}
      <legend style={{ color: `rgb(${formData.colors.deep})` }}>{familyType} 측</legend>
      {formData?.familyInfo[position].map((familyMember, index) => (
        <div key={`${position}FamilyInfo`} className={styles.family_wrapper}>
          <div className={styles.input_wrapper}>
            <label>성함</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='text'
              value={familyMember.name}
              onChange={(e) => handleChange(e, 'familyInfo', `${position}`, index, 'name')}
            />
          </div>
          {familyMember.role === 'me' ? (
            <div className={styles.input_wrapper}>
              <label>서열 호칭</label>
              <input
                style={{
                  backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                  outlineColor: `rgba(${formData.colors.main}, 1)`,
                }}
                type='text'
                value={familyMember.position}
                onChange={(e) => handleChange(e, 'familyInfo', `${position}`, index, 'position')}
              />
            </div>
          ) : (
            <div className={styles.input_wrapper}>
              <label>{`${familyType}${hasJongseong(familyType) ? '과' : '와'}의 관계`}</label>
              <select
                style={{
                  backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                  outlineColor: `rgba(${formData.colors.main}, 1)`,
                }}
                value={familyMember.role}
                onChange={(e) => handleChange(e, 'familyInfo', `${position}`, index, 'role')}
              >
                {renderOption(familyMembers)}
                {showMoreFamily ? renderOption(moreFamilyMembers) : <option value='moreFamily'>+더 불러오기</option>}
              </select>
            </div>
          )}
          <div className={styles.input_wrapper}>
            <label>휴대폰 번호</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='text'
              value={familyMember.phone}
              onChange={(e) => handleChange(e, 'familyInfo', `${position}`, index, 'phone')}
            />
          </div>
          <div className={styles.input_wrapper}>
            <label>계좌번호</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='text'
              value={familyMember.accountNumber}
              onChange={(e) => handleChange(e, 'familyInfo', `${position}`, index, 'accountNumber')}
            />
          </div>
          <hr />
        </div>
      ))}
      <button onClick={() => addFamilyMember(`${position}`)}>+ 혼주 추가하기</button>
    </fieldset>
  );
};

export default FamilySetting;
