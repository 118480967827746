import React from 'react';

import styles from './Index.module.css';
import header from '../../../assets/index_header.jpg';
import ornament from '../../../assets/path60.svg';

const Index = () => {
  const handleClick = ($url) => {
    window.open($url);
  };

  const handleClickButton = ($url) => {
    window.location.href = `/wedding/${$url}`;
  };

  const handleClickDisabledButton = ($url) => {
    alert(`${$url}은 준비중입니다. 출시를 기다려 주세요!`);
  };

  return (
    <div className={styles.Index}>
      <div className={styles.box}>
        <div className={styles.header}>
          <img src={header} alt='header' />
        </div>
        <div className={styles.contents}>
          <section className={styles.ad}>
            <p>내 취향에</p>
            <p>
              <b>딱</b> <span className={styles.replace} />
            </p>
            <p>모바일 청첩장</p>
          </section>

          <section className={styles.title}>
            <p>맞춤형 모바일 청첩장 서비스</p>
            <p>Happy ever after</p>
          </section>

          <img src={ornament} alt='ornament' className={styles.ornament} />

          <section className={styles.sample_preview_mockup}>
            <div className={styles.templates_contents}>
              <p>⚫</p>
              <h3>Sample Preview</h3>
              <div className={styles.buttons_wrapper}>
                <div>
                  <button onClick={() => handleClickButton('template01')}>🤍 Template 01</button>
                  <span>#고급스러운</span>
                </div>
                <div>
                  <button onClick={() => handleClickButton('template02')}>🤍 Template 02</button>
                  <span>#깔끔한 </span>
                </div>
                <div>
                  <button onClick={() => handleClickButton('template03')}>🤍 Template 03</button>
                  <span>#영화포스터 </span>
                </div>

                <div className={styles.dots}>
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </div>

                <div>
                  <button className={styles.custom}>
                    🩷 <i>Your own custom</i> 🩷
                  </button>
                  <span>#완벽한 </span>
                </div>
              </div>
            </div>
          </section>

          <img src={ornament} alt='ornament' className={styles.ornament} />

          <section className={styles.notice}>
            <p>전달받은 모바일 청첩장이 있으신가요?</p>
            <p>신랑/신부로부터 전달받은 링크를 클릭하시면</p>
            <p>모바일 청첩장을 확인하실 수 있습니다.</p>
          </section>
        </div>
        <div className={styles.footer}>
          <p>
            kmong “
            <span onClick={() => handleClick('https://kmong.com/')}>내 취향에 꼭 맞추는 모바일 청첩장 주문제작</span>”
          </p>
          <p>
            Designed & Developed by <b>Malg Eum Yu</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Index;
