import loadingImg from '../../../assets/Loading.svg';

const Loading = () => {
  return (
    <div className='Modal'>
      <img src={loadingImg} alt='Loading' height={200} width={200} style={{ width: '100px' }} />
    </div>
  );
};

export default Loading;
