import React from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';

import DynamicMain from './components/Main/DynamicMain';
import Index from './components/Common/Index/Index';
import Settings from './components/Settings/Settings';
import Login from './components/Settings/Login';

import './styles/reset.css';
import './App.css';
import './styles/default.css';

const App = () => {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/wedding/:id' element={<DynamicMain />} />
          <Route path='/settings/login' element={<Login />} />
          <Route path='/settings/:id' element={<Settings />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
