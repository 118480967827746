import React from 'react';

const WeddingSetting = ({ styles, formData, setFormData }) => {
  const handleChange = (e, section, index, key) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (index !== null) {
        newData[section][index][key] = value;
      } else {
        newData[section][key] = value;
      }
      return newData;
    });
  };

  return (
    <>
      {/* Wedding Date */}
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='date'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>예식 일시</legend>
        <div className={styles.datetime_wrapper}>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='date'
            defaultValue={`${formData.weddingDate.year}-${formData.weddingDate.month}-${formData.weddingDate.day}`}
          />
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='time'
            defaultValue={formData.weddingDate.time}
          />
        </div>
      </fieldset>

      {/* Location Info */}
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='location'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>웨딩홀 위치</legend>
        <div className={styles.input_wrapper} style={{ display: 'none' }}>
          <label>좌표</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.coordinates.join(', ')}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: { ...formData?.locationInfo, coordinates: e.target.value.split(', ') },
              })
            }
          />
        </div>

        <div className={styles.input_wrapper}>
          <label>도로명주소</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.roadAddress}
            onChange={(e) =>
              setFormData({ ...formData, locationInfo: { ...formData?.locationInfo, roadAddress: e.target.value } })
            }
          />
        </div>
        <div className={styles.input_wrapper}>
          <label>지번주소</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.jibunAddress}
            onChange={(e) =>
              setFormData({ ...formData, locationInfo: { ...formData?.locationInfo, jibunAddress: e.target.value } })
            }
          />
        </div>
        <div className={styles.input_wrapper}>
          <label>상세주소</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.detailAddress}
            onChange={(e) =>
              setFormData({ ...formData, locationInfo: { ...formData?.locationInfo, detailAddress: e.target.value } })
            }
          />
        </div>
        <div className={styles.input_wrapper}>
          <label>웨딩홀</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.venue}
            onChange={(e) =>
              setFormData({ ...formData, locationInfo: { ...formData?.locationInfo, venue: e.target.value } })
            }
          />
        </div>
      </fieldset>

      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='map'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>지도 바로가기 링크</legend>
        <div className={styles.notice}>📢추가를 원하지 않는 지도는 비워 두시면 됩니다.</div>
        <div className={styles.input_wrapper}>
          <div>
            <label>네이버지도</label>
          </div>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.url.naver || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: {
                  ...formData?.locationInfo,
                  url: { ...formData?.locationInfo.url, naver: e.target.value },
                },
              })
            }
          />
        </div>

        <div className={styles.input_wrapper}>
          <div>
            <label>카카오맵</label>
          </div>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.url.kakao || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: {
                  ...formData?.locationInfo,
                  url: { ...formData?.locationInfo.url, kakao: e.target.value },
                },
              })
            }
          />
        </div>

        <div className={styles.input_wrapper}>
          <div>
            <label>T Map</label>
          </div>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.url.tmap || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: {
                  ...formData?.locationInfo,
                  url: { ...formData?.locationInfo.url, tmap: e.target.value },
                },
              })
            }
          />
        </div>

        <div className={styles.input_wrapper}>
          <div>
            <label>Google Maps</label>
          </div>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.locationInfo.url.google || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: {
                  ...formData?.locationInfo,
                  url: { ...formData?.locationInfo.url, google: e.target.value },
                },
              })
            }
          />
        </div>
      </fieldset>
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='transportation'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>교통/주차 정보</legend>

        <div className={styles.input_wrapper}>
          <label>버스 정보</label>
          <textarea
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            rows={2}
            value={formData?.locationInfo.transportation.bus}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: {
                  ...formData?.locationInfo,
                  transportation: { ...formData?.locationInfo.transportation, bus: e.target.value },
                },
              })
            }
          />
        </div>
        <div className={styles.input_wrapper}>
          <label>지하철 정보</label>
          <textarea
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            rows={2}
            value={formData?.locationInfo.transportation.subway}
            onChange={(e) =>
              setFormData({
                ...formData,
                locationInfo: {
                  ...formData?.locationInfo,
                  transportation: { ...formData?.locationInfo.transportation, subway: e.target.value },
                },
              })
            }
          />
        </div>
        <div className={styles.input_wrapper}>
          <label>주차 정보</label>
          <textarea
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            rows={2}
            value={formData?.locationInfo.parkingInfo}
            onChange={(e) =>
              setFormData({ ...formData, locationInfo: { ...formData?.locationInfo, parkingInfo: e.target.value } })
            }
          />
        </div>
      </fieldset>

      {/* Invitation */}
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='invitation'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>초대사 문구</legend>
        <textarea
          style={{
            backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
            outlineColor: `rgba(${formData.colors.main}, 1)`,
          }}
          rows={7}
          onChange={(e) => handleChange(e, 'invitation', '')}
        >
          {formData?.invitation.join('\n')}
        </textarea>
      </fieldset>
    </>
  );
};

export default WeddingSetting;
