import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useModal from '../../hooks/useModal';

import Loading from '../Common/Loading/Loading';
import useGetAuthState from '../../api/useGetAuthState';
import GallerySetting from '../SettingComponents/GallerySetting';
import WeddingSetting from '../SettingComponents/WeddingSetting';
import PageSetting from '../SettingComponents/PageSetting';
import FamilySetting from '../SettingComponents/FamilySetting';
import styles from './Settings.module.css';
import useRealtimeDB from '../../api/useRealtimeDB';
import Menus from './Menus';

const Settings = () => {
  const navigate = useNavigate();

  // const [uid, setUid] = useState(null);
  // const authUID = useGetAuthState();

  // useEffect(() => {
  //   if (authUID) {
  //     setUid(authUID);
  //   }
  // }, [authUID]);

  const { id } = useParams();
  const { data, isLoading } = useRealtimeDB({ alias: id });

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (!isLoading && data === 'No data available') {
      navigate('/');
    } else if (!isLoading && data) {
      setFormData({ ...data });
    }
  }, [data, isLoading]);

  const { isModalVisible, openModal, closeModal } = useModal();
  const handleMenuClick = () => {
    if (isModalVisible) {
      closeModal();
    } else {
      openModal();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // formData를 사용하여 필요한 작업 수행
  };

  return (
    <div className={styles.background} style={{ backgroundColor: `rgb(${formData?.colors.light})` }}>
      {isModalVisible && <Menus formData={formData} styles={styles} closeModal={closeModal} />}
      <div className={styles.Settings}>
        {formData ? (
          <>
            <div className={styles.header} style={{ backgroundColor: `rgba(${formData?.colors.deep}, 0.85)` }}>
              <span />
              <span>Settings</span>
              <div className={styles.menu} onClick={handleMenuClick}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  fill='currentColor'
                  className='bi bi-three-dots-vertical'
                  viewBox='0 0 16 16'
                >
                  <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
                </svg>
              </div>
            </div>
            <div className={styles.contents}>
              <form onSubmit={handleSubmit}>
                <section id='page'>
                  <h2 style={{ borderColor: `rgb(${formData?.colors.point})` }}>페이지 설정</h2>
                  <PageSetting styles={styles} formData={formData} setFormData={setFormData} />
                </section>

                <section id='wedding'>
                  <h2 style={{ borderColor: `rgb(${formData?.colors.point})` }}>결혼식 정보 설정</h2>
                  <WeddingSetting styles={styles} formData={formData} setFormData={setFormData} />
                </section>

                <section id='family'>
                  <h2 style={{ borderColor: `rgb(${formData?.colors.point})` }}>가족 정보 설정</h2>
                  <FamilySetting position='bride' styles={styles} formData={formData} setFormData={setFormData} />
                  <FamilySetting position='groom' styles={styles} formData={formData} setFormData={setFormData} />
                </section>

                <section id='gallery'>
                  <h2 style={{ borderColor: `rgb(${formData?.colors.point})` }}>갤러리 설정</h2>
                  <GallerySetting styles={styles} formData={formData} setFormData={setFormData} />
                </section>

                <div className={styles.button_wrapper}>
                  <button type='button' onClick={() => window.location.reload()}>
                    초기화
                  </button>
                  <button style={{ backgroundColor: `rgb(${formData?.colors.deep})`, color: 'white' }} type='submit'>
                    저장
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <Loading />
        )}
        <div className={styles.footer} style={{ backgroundColor: `rgb(${formData?.colors.deep})` }}>
          맞춤형 모바일 청첩장 서비스 Happy Ever After
        </div>
      </div>
    </div>
  );
};

export default Settings;
