import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../Common/Loading/Loading';

const DynamicMain = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [dbData, setDbData] = useState(null);
  const [MainComponent, setMainComponent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/storage/${id}.json`);
        const data = await response.json();

        setDbData(data);

        if (data) {
          const component = React.lazy(() => import(`./${data.alias}/Main`));
          setMainComponent(component);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching the data:', error);
        navigate('/');
      }
    };

    fetchData();
  }, [navigate]);

  if (!dbData || !MainComponent) {
    return <Loading />;
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <MainComponent dbData={dbData} />
    </React.Suspense>
  );
};

export default DynamicMain;
