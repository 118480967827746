const SettingMenuList = [
  {
    name: '페이지 설정',
    href: 'page',
  },
  {
    name: '결혼식 정보 설정',
    href: 'wedding',
  },
  {
    name: '가족 정보 설정',
    href: 'family',
  },
  {
    name: '갤러리 설정',
    href: 'gallery',
  },
];

export default SettingMenuList;
