import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';

import { firebaseConfig } from './firebaseConfig';

const setRealtimeDB = (uid, data) => {
  if (!data || !uid) return;

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  set(ref(database, `users/${uid}`), data)
    .then(() => {
      console.log('Data saved successfully.');
    })
    .catch((error) => {
      console.error(`Data could not be saved.${error}`);
    });
};

export default setRealtimeDB;
