import { useEffect, useState } from 'react';
import { getDatabase, ref, child, get } from 'firebase/database';

import app from './firebaseApp';

const useRealtimeDB = ({ alias }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Firebase 초기화
    const database = getDatabase(app);
    const rootRef = ref(database);
    const query = child(rootRef, alias);

    // 데이터 가져오기
    get(query)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setData(snapshot.val());
        } else {
          console.log('No data available');
          setData('No data available');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [alias]);

  return { data, isLoading };
};

export default useRealtimeDB;
