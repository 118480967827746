import React from 'react';

const PageSetting = ({ styles, formData, setFormData }) => {
  const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (+r << 16) + (+g << 8) + b / 1).toString(16).slice(1)}`;
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  return (
    <>
      {/* Page Header */}
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='headerText'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>상단바 (헤더)</legend>
        <div className={styles.input_wrapper}>
          <label>헤더 문구</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.headerText}
            onChange={(e) => setFormData({ ...formData, headerText: e.target.value })}
          />
        </div>
      </fieldset>

      {/* Title */}
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='titleText'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>타이틀 페이지</legend>
        <div className={styles.input_wrapper}>
          <label>상단부 문구</label>
          <input
            style={{
              backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
              outlineColor: `rgba(${formData.colors.main}, 1)`,
            }}
            type='text'
            value={formData?.titleTopText}
            onChange={(e) => setFormData({ ...formData, titleTopText: e.target.value })}
          />
        </div>
        {formData?.titleLettering && (
          <div className={styles.input_wrapper}>
            <label>레터링</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='text'
              value={formData?.titleLettering}
              onChange={(e) => setFormData({ ...formData, titleLettering: e.target.value })}
            />
          </div>
        )}
      </fieldset>

      {/* Colors */}
      <fieldset style={{ borderColor: `rgb(${formData.colors.deep})` }} name='colors'>
        <legend style={{ color: `rgb(${formData.colors.deep})` }}>색상</legend>
        <div className={styles.colors_wrapper}>
          <div>
            <label>메인</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='color'
              value={rgbToHex(...formData.colors.main.split(','))}
              onChange={(e) =>
                setFormData({ ...formData, colors: { ...formData?.colors, main: hexToRgb(e.target.value) } })
              }
            />
          </div>
          <div>
            <label>밝은</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='color'
              value={rgbToHex(...formData.colors.light.split(','))}
              onChange={(e) =>
                setFormData({ ...formData, colors: { ...formData?.colors, light: hexToRgb(e.target.value) } })
              }
            />
          </div>
          <div>
            <label>어두운</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='color'
              value={rgbToHex(...formData.colors.deep.split(','))}
              onChange={(e) =>
                setFormData({ ...formData, colors: { ...formData?.colors, deep: hexToRgb(e.target.value) } })
              }
            />
          </div>
          <div>
            <label>포인트</label>
            <input
              style={{
                backgroundColor: `rgba(${formData.colors.main}, 0.3)`,
                outlineColor: `rgba(${formData.colors.main}, 1)`,
              }}
              type='color'
              value={rgbToHex(...formData.colors.point.split(','))}
              onChange={(e) =>
                setFormData({ ...formData, colors: { ...formData?.colors, point: hexToRgb(e.target.value) } })
              }
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default PageSetting;
