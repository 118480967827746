import React from 'react';
import { Fade } from 'react-reveal';

import useModal from '../../hooks/useModal';

import MenuList from './MenuList';

const Menus = ({ formData, styles, closeModal }) => {
  const { handleContentClick } = useModal();

  const handleMenuClick = (href) => {
    const targetElement = document.querySelector(`#${href}`);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 80,
        behavior: 'smooth',
      });
    }
    closeModal();
  };

  return (
    <div className='Modal menu' onClick={closeModal}>
      <div className='modal_wrapper' onClick={handleContentClick}>
        <Fade>
          <div className={`${styles.Menus}`} style={{ backgroundColor: `rgba(${formData.colors.main}, 0.5)` }}>
            {MenuList.map((item) => (
              <div key={item.name} onClick={() => handleMenuClick(item.href)}>
                {item.name}
              </div>
            ))}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Menus;
